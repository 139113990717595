import { IProductUnit } from 'shared/models/product.model';

export class UnitOkeiHelper {
  public static getModelUnitOkeiName(unit: IProductUnit | undefined): string {
    if (!unit?.okei?.russianUnit) {
      return 'шт';
    }

    return unit?.okei?.russianUnit;
  }

  public static getModelUnitPrecision(unit: IProductUnit | undefined): number {
    if (!unit?.precision) {
      return 0;
    }

    return unit.precision;
  }

  public static getUnitOkeiCode(unit?: IProductUnit): string | undefined {
    if (!unit?.okei?.code) {
      return;
    }

    return unit.okei.code;
  }
}
